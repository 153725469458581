import { Alert, Button } from "antd";
import notFoundData from "assets/lotties/not-found.json";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SHARE_PATH } from "_constants/path";

function NotFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Lottie options={defaultOptions} height={400} width={400} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 12,
        }}
      >
        <Alert message="Trang không tồn tại..." type="error" showIcon />
      </div>
      <Link to={SHARE_PATH}>
        <Button type="primary" icon={<ArrowLeftOutlined />}>
          Quay về trang chủ
        </Button>
      </Link>
    </div>
  );
}

NotFound.propTypes = {};

export default NotFound;
