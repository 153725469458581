import { storage } from "_constants";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { LOGIN_PATH } from "_constants/path";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem(storage.ACCESS_TOKEN);
  return token ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: LOGIN_PATH,
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
