import {
  BookOutlined,
  CodepenOutlined,
  PicLeftOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { lazy } from "react";

const ShareList = lazy(() => import("features/shares/containers/ShareList"));

const Transaction = lazy(() =>
  import("features/transaction/containers/Transaction")
);
const TransactionMany = lazy(() =>
  import("features/transaction/containers/TransactionMany")
);

const StakeholderList = lazy(() =>
  import("features/stakeholder/containers/StakeholderList")
);
const StakeholderAdd = lazy(() =>
  import("features/stakeholder/containers/StakeholderAdd")
);
const StakeholderDetail = lazy(() =>
  import("features/stakeholder/containers/StakeholderDetail")
);

const TransactionHistory = lazy(() => import("features/transaction-history"));

const UserList = lazy(() => import("features/user/containers/UserList"));
const UserAdd = lazy(() => import("features/user/containers/UserAdd"));
const UserDetail = lazy(() => import("features/user/containers/UserDetail"));

const UserTypeList = lazy(() =>
  import("features/user-type/containers/UserTypeList")
);

// const PermissionList = lazy(() =>
//   import("features/permission/containers/PermissionList")
// );

const _nav = [
  {
    key: "/shares",
    label: "Quản lý cổ phần",
    title: "Quản lý cổ phần",
    action_key: "VIEW_DASHBOARD",
    display: 1,
    icon: <ShareAltOutlined />,
    component: props => <ShareList {...props} />,
  },
  {
    key: "/transactions",
    label: "Giao dịch mua bán",
    title: "Giao dịch mua bán",
    action_key: "VIEW_USER",
    icon: <CodepenOutlined />,
    display: 1,
    children: [
      {
        key: "/transactions/one",
        label: "Chuyển nhượng CP",
        title: "Chuyển nhượng CP",
        action_key: "VIEW_USER",
        display: 1,
        component: props => <Transaction {...props} />,
      },
      {
        key: "/transactions/many",
        label: "Tăng giảm CP",
        title: "Tăng giảm CP",
        action_key: "VIEW_USER",
        display: 1,
        component: props => <TransactionMany {...props} />,
      },
    ],
  },
  {
    key: "/stakeholders",
    label: "Danh sách cổ đông",
    title: "Danh sách cổ đông",
    action_key: "VIEW_USER",
    display: 1,
    icon: <PicLeftOutlined />,
    component: props => <StakeholderList {...props} />,
    children: [
      {
        key: "/stakeholders/add",
        action_key: "VIEW_USER",
        label: "Thêm cổ đông",
        title: "Thêm cổ đông",
        component: props => <StakeholderAdd {...props} />,
      },
      {
        key: "/stakeholders/:id",
        action_key: "VIEW_USER_JOB",
        label: "Chi tiết cổ đông",
        title: "Chi tiết cổ đông",
        component: props => <StakeholderDetail {...props} />,
      },
    ],
  },
  {
    key: "/transaction-history",
    label: "Nhật ký giao dịch",
    title: "Nhật ký giao dịch",
    action_key: "VIEW_USER",
    display: 1,
    icon: <BookOutlined />,
    component: props => <TransactionHistory {...props} />,
  },
  {
    key: "/configs",
    label: "Thiết lập",
    title: "Thiết lập",
    action_key: "VIEW_USER",
    icon: <SettingOutlined />,
    display: 1,
    children: [
      {
        key: "/users",
        action_key: "VIEW_USER",
        label: "Quản lý người dùng",
        title: "Quản lý người dùng",
        display: 1,
        component: props => <UserList {...props} />,
      },
      {
        key: "/users/add",
        action_key: "VIEW_USER",
        label: "Thêm người dùng",
        title: "Thêm người dùng",
        component: props => <UserAdd {...props} />,
      },
      {
        key: "/users/:id",
        action_key: "VIEW_USER_JOB",
        label: "Chi tiết người dùng",
        title: "Chi tiết người dùng",
        component: props => <UserDetail {...props} />,
      },
      {
        key: "/user-types",
        action_key: "VIEW_USER",
        label: "Quản lý loại người dùng",
        title: "Quản lý loại người dùng",
        display: 1,
        component: props => <UserTypeList {...props} />,
      },
      // {
      //   key: "/permissions",
      //   action_key: "VIEW_USER",
      //   label: "Quản lý phân quyền",
      //   title: "Quản lý phân quyền",
      //   display: 1,
      //   component: props => <PermissionList {...props} />,
      // },
    ],
  },
];

export const getNavigation = ({
  navs = _.cloneDeep(_nav),
  isRoute = false,
} = {}) => {
  return navs.reduce((result, nav, index) => {
    let { children } = nav;
    let isDisplayForNav = !!nav.display; // display in left navigation
    if (isDisplayForNav || isRoute) result.push(nav);
    if (children?.length > 0) {
      let childNavs = getNavigation({ navs: children, isRoute }) ?? []; // recursive children
      if (isRoute) {
        if (!nav.component) result.splice(result.length - 1);
        result = [...result, ...childNavs];
      } else {
        if (childNavs.length > 0) result[index].children = [...childNavs];
        else delete result[index].children;
      }
    }
    return result;
  }, []);
};

export default _nav;
